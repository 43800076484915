@font-face {
  font-family: 'Nunito';
  src: local('Nunito'), url('/public/fonts/Nunito/Nunito-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito-Italic';
  src: local('Nunito'), url('/public/fonts/Nunito/Nunito-Italic-VariableFont_wght.ttf') format('truetype');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --app-bg-color: #f1f2f3;
  --app-max-width: 1530px; /* based on retool max width */
  --app-horizontal-padding: 20px;

  --app-header-fg: #fff;
}

html, body {
  font-family: 'Nunito';
  padding: 0;
  margin: 0;
  overflow: hidden;
  background: var(--app-bg-color);
}

.app-header {
  width: 100%;
  text-align: left;
  height: 50px;
  position: relative;
  background: #0e1726;
  display: flex;
  justify-content: center;
  margin-right: 15px;
}

.app-header-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 var(--app-horizontal-padding);
}

.app-header > .app-header-container > img {
  height: 80%;
  overflow: unset;
}

.app-body {
  /* height: calc(100vh - 50px); */
  height: 100vh;
}

@media screen and (max-width: 1560px){
  .app-header-container {
    width: 100%;
  }
}

@media screen and (min-width: 1560px){
  .app-header-container {
    width: 1530px;
  }
}