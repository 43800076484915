
:root {
    --splash-fg-color: #fff;
    --splash-width: 80vw;
    --splash-max-width: 1000px;
    --splash-height: 350px;
    --splash-progress-bar-height: 10px;
}

.splash-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;   
    background: var(--app-bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.splash {
    position: relative;
    background: var(--splash-fg-color);
    width: var(--splash-width);
    max-width: var(--splash-max-width);
    height: var(--splash-height);
}

.splash .splash-logo {
    padding: 1rem 3rem 4rem 3rem;
    box-sizing: border-box;
    width: 100%;
    height: calc(var(--splash-height) - var(--splash-progress-bar-height));
}

.splash .splash-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.splash .splash-status {
    position: relative;
    height: var(--splash-progress-bar-height);
}