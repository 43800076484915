
.progress-bar-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.progress-bar-container .progress {
    height: 20px;
    border-radius: 0;
}

.progress-bar-container .progress .progress-bar {
    background-color: #787878;
    color: #e7e7e7;
}